import React, { useState, useEffect, Fragment } from "react";
import { Card, Form, Input, Table, Select, Button, DatePicker, Checkbox, message } from "antd";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import FormItem from "antd/es/form/FormItem";
import swal from "sweetalert";

const { Option } = Select;

function FormE(props) {
  const [dataHeader, setDataHeader] = useState([""]);
  const nppbkc = props.nppbkc;

  useEffect(() => {
    const getDataHeader = () => {
      axios
        .get("https://one-delta-api-1020394607917.asia-southeast2.run.app/registrasi/detil/reg-E?nppbkc=" + nppbkc, {
          headers: {},
        })
        .then((res) => {
          const result = res.data.body.data.data;
          setDataHeader(result);
          // console.log("HEADER: ", result);
        })
        .catch((error) => {});
    };

    getDataHeader();
  }, [nppbkc]);

  const onFinish = (values) => {
    const formData = new FormData();
    formData.append(
      "detils",
      JSON.stringify({
        jenisAkun: values.jenisAkun,
        idRegE: "",
        idRegEAkun: "",
        jumlah: "",
      })
    );

    formData.append("aplikasiSistemAkuntansi", values.aplikasiSistemAkuntansi);
    formData.append("chartAccount", values.chartAccount);
    formData.append("bukuBesar", values.bukuBesar);
    formData.append("bukuPembantu", values.bukuPembantu);
    formData.append("kotaPerubahan", values.kotaPerubahan);
    formData.append("flagSistemAkuntansi", values.flagSistemAkuntansi);
    formData.append("idRegE", values.idRegE);
    formData.append("idRegistrasi", values.idRegistrasi);
    formData.append("jurnalPenyesuaian", values.jurnalPenyesuaian);
    formData.append("jurnalUmum", values.jurnalUmum);
    formData.append("lapArusKas", values.lapArusKas);
    formData.append("lapCatatan", values.lapCatatan);
    formData.append("lapEkuitas", values.lapEkuitas);
    formData.append("lapKeuangan", values.lapKeuangan);
    formData.append("lapLabaRugi", values.lapLabaRugi);
    formData.append("neraca", values.neraca);
    formData.append("neracaSaldo", values.neracaSaldo);
    formData.append("nomorPkp", values.nomorPkp);
    formData.append("periodeLaporanKeuangan", values.periodeLaporanKeuangan);
    formData.append("rekonsiliasiBank", values.rekonsiliasiBank);
    formData.append("statusPajak", values.statusPajak);
    formData.append("tanggalLaporanKeuangan", values.tanggalLaporanKeuangan);
    formData.append("tanggalPkp", values.tanggalPkp);
    formData.append("unitAuditInternal", values.unitAuditInternal);
    formData.append("usahaPajak", values.usahaPajak);

    const options = {
      method: "POST",
      url: "https://one-delta-api-1020394607917.asia-southeast2.run.app/registrasi/simpan-reg-E",
      data: formData,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
      },
    };
    console.log(formData);
    axios(options)
      .then(function (response) {
        console.log(response);
        swal({
          title: "Selamat!",
          text: "Berhasil Rekam Mesin!",
          icon: "success",
        }).then((value) => {});
      })
      .catch(function (error) {
        message.error("Gagal simpan data");
        console.log(error);
      });
  };

  return (
    <Fragment>
      <div>
        <Card title="Data Perpajakan, Keuangan, dan Pembukuan">
          <div className="kt-portlet__body">
            <div className="kt-portlet__head-toolbar"></div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Status Wajib Pajak</label>
              <div className="col-3">
                <FormItem name="statusPajak">
                  <Select style={{ width: "100%" }} />
                </FormItem>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Nomor PKP</label>
              <div className="col-3">
                <FormItem name="nomorPkp">
                  <Input style={{ width: "100%" }} type="number" />
                </FormItem>
              </div>
              <label className="col-1 col-form-label"></label>
              <label className="col-2 col-form-label">Tanggal PKP</label>
              <div className="col-3">
                <FormItem name="tanggalPkp">
                  <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
                </FormItem>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Berdasarkan Pasal 16 UU Cukai Nomor 39 Tahun 2007, Perusahaan anda Menyelenggarakan</label>
              <div className="col-3">
                <FormItem name="">
                  <Select style={{ width: "100%" }} />
                </FormItem>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <h6 class="kt-portlet__head-title">Pertanyaan Berikut, ditujukan Khusus untuk perusahaan yang menyelenggarakan pembukuan</h6>
            </div>
            <div className="form-group form-group-xs row">
              <h7 class="kt-portlet__head-title">Posisi Keuangan Perusahaan (berdasarkan laporan keuangan pada akhir tahun buku)</h7>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Tahun Laporan Keuangan</label>
              <div className="col-3">
                <FormItem name="">
                  <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
                </FormItem>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <h6 class="kt-portlet__head-title">Rekam Laporan Keuangan</h6>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Jenis Akun</label>
              <div className="col-3">
                <FormItem name="jenisAkun">
                  <Select style={{ width: "100%" }} />
                </FormItem>
              </div>
              <label className="col-1 col-form-label"></label>
              <label className="col-2 col-form-label">Jumlah</label>
              <div className="col-3">
                <FormItem name="jumlah">
                  <Input style={{ width: "100%" }} />
                </FormItem>
              </div>
            </div>

            <div className="form-group form-group-xs row">
              <div className="col-3">
                <Button type="primary" htmlType="submit" block>
                  Submit
                </Button>
              </div>
              <div className="col-3">
                <Button type="primary">Reset</Button>
              </div>
            </div>
          </div>
        </Card>
        <Card>
          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <Table />
          </div>
        </Card>
        <Card>
          <div className="form-group form-group-xs row">
            <label className="col-2 col-form-label">Memiliki Sistem Akuntansi</label>
            <div className="col-3">
              <FormItem name="">
                <Select style={{ width: "100%" }} />
              </FormItem>
            </div>
            <label className="col-1 col-form-label"></label>
            <label className="col-2 col-form-label">Aplikasi Sistem Akuntansi</label>
            <div className="col-3">
              <FormItem name="aplikasiSistemAkuntansi">
                <Select style={{ width: "100%" }} />
              </FormItem>
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-2 col-form-label">Bagan Rek./Chart of Account</label>
            <div className="col-3">
              <FormItem name="chartAccount">
                <Select style={{ width: "100%" }} />
              </FormItem>
            </div>
            <label className="col-1 col-form-label"></label>
            <label className="col-2 col-form-label">Periode Laporan Keuangan</label>
            <div className="col-3">
              <FormItem name="periodeLaporanKeuangan">
                <Select style={{ width: "100%" }} />
              </FormItem>
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <h7 class="kt-portlet__head-title">Jenis/Komponen Laporan Keuangan</h7>
          </div>
          <div className="form-group form-group-xs row">
            <div className="col-3">
              <FormItem name="lapLabaRugi">
                <Checkbox value="1">Laporan Laba/Rugi</Checkbox>
              </FormItem>
            </div>
            <div className="col-3">
              <FormItem name="lapEkuitas">
                <Checkbox value="2">Laporan Perubahan Modal(Ekuitas)</Checkbox>
              </FormItem>
            </div>
            <div className="col-3">
              <FormItem name="neraca">
                <Checkbox value="3">Neraca</Checkbox>
              </FormItem>
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <div className="col-3">
              <FormItem name="lapArusKas">
                <Checkbox value="1">Laporan Arus Kas</Checkbox>
              </FormItem>
            </div>
            <div className="col-3">
              <FormItem name="">
                <Checkbox value="2">Catatan atas Laporan Keuangan</Checkbox>
              </FormItem>
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-2 col-form-label">Memiliki Unit Audit Internal</label>
            <div className="col-3">
              <FormItem name="unitAuditInternal">
                <Select style={{ width: "100%" }} />
              </FormItem>
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <h7 class="kt-portlet__head-title">Komponen Pembukuan/akuntansi yang dimiliki Perusahaan</h7>
          </div>
          <div className="form-group form-group-xs row">
            <div className="col-3">
              <FormItem name="jurnalUmum">
                <Checkbox value="1">Jurnal Umum (General Jurnal)</Checkbox>
              </FormItem>
            </div>
            <div className="col-3">
              <FormItem name="jurnalPenyesuaian">
                <Checkbox value="2">Jurnal Penyesuaian(Adjusting Entry)</Checkbox>
              </FormItem>
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <div className="col-3">
              <FormItem name="bukuBesar">
                <Checkbox value="1">Buku Besar(General Ledger)</Checkbox>
              </FormItem>
            </div>
            <div className="col-3">
              <FormItem name="neracaSaldo">
                <Checkbox value="2">Neraca Saldo(Trial Balance)</Checkbox>
              </FormItem>
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <div className="col-3">
              <FormItem name="bukuPembantu">
                <Checkbox value="1">Buku Pembantu(Subsidiary Ledger)</Checkbox>
              </FormItem>
            </div>
            <div className="col-3">
              <FormItem name="lapKeuangan">
                <Checkbox value="2">Laporan Keuangan</Checkbox>
              </FormItem>
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <div className="col-3">
              <FormItem name="rekonsiliasiBank">
                <Checkbox value="1">Rekonsiliasi Bank</Checkbox>
              </FormItem>
            </div>
          </div>
        </Card>
        <div className="form-group form-group-xs row">
          <label className="col-2 col-form-label"></label>
          <label className="col-2 col-form-label"></label>
          <label className="col-1 col-form-label"></label>
          <div className="col-3">
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default FormE;
