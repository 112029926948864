import React, { useState, useEffect, Fragment, useRef } from "react";
import { Card, Form, Input, Image } from "antd";
import axios from "axios";
import html2canvas from "html2canvas";
import { QRCodeCanvas } from "qrcode.react";
import logoBeaCukai from "./bea-cukai.png";

function DetilMesin(props) {
  const [dataHeader, setDataHeader] = useState([""]);
  const id_mesin = props.id_mesin;
  const [visible, setVisible] = useState(false);
  const token = localStorage.getItem("token");
  const [isPrinting, setIsPrinting] = useState(false);
  const qrCodeRef = useRef(null);

  useEffect(() => {
    const getDataHeader = () => {
      axios
        .get("https://one-delta-api-1020394607917.asia-southeast2.run.app/mesin/detil-mesin?id=" + id_mesin, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: token,
          },
        })
        .then((res) => {
          const result = res.data.body.data.data[0];
          setDataHeader(result);
        })
        .catch((error) => {});
    };

    getDataHeader();
  }, [id_mesin, token]);
  const printQRCode = () => {
    setIsPrinting(true);
    html2canvas(qrCodeRef.current).then((canvas) => {
      const qrcodeImage = canvas.toDataURL("image/png");

      const printWindow = window.open("", "_blank");
      printWindow.document.write("<html><body>");
      printWindow.document.write(`<img src="${qrcodeImage}" style="width:9cm;height:6cm;"/>`);
      printWindow.document.write("</body></html>");
      printWindow.print();
      printWindow.close();

      setIsPrinting(false);
    });
  };
  return (
    <Fragment>
      <div>
        <Card title="DATA HEADER">
          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"NPPBKC"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.nppbkc} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"NAMA PERUSAHAAN"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.nama_perusahaan} />
              </Form.Item>
            </div>
          </div>
        </Card>
        <Card title="DATA MESIN">
          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"MERK"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.merk} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"JENIS MESIN"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.jenis_mesin} />
              </Form.Item>
            </div>
          </div>

          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"SERIAL NUMBER"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.serial_number} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"TIPE MESIN"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.tipe_mesin} />
              </Form.Item>
            </div>
          </div>

          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"JENIS PRODUKSI"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.jenis_produksi} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"TAHUN PEMBUATAN"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.tahun_pembuatan} />
              </Form.Item>
            </div>
          </div>

          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"KAPASITAS"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.kapasitas} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"NEGARA PEMBUAT"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.negara_pembuat} />
              </Form.Item>
            </div>
          </div>
          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"KONDISI"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.kondisi} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"STATUS"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.status_kepemilikkan} />
              </Form.Item>
            </div>
          </div>
          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"FOTO MESIN"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {dataHeader &&
                    dataHeader.foto_mesin &&
                    Array.isArray(dataHeader.foto_mesin) &&
                    dataHeader.foto_mesin.length > 0 &&
                    dataHeader.foto_mesin.map((foto, index) =>
                      foto.url_foto ? ( // Periksa apakah foto memiliki URL valid
                        <div key={index} style={{ margin: "4px" }}>
                          <Image
                            preview={{
                              visible: false,
                            }}
                            width={120}
                            height={120}
                            src={foto.url_foto}
                            onClick={() => setVisible(true)}
                            fallback="https://via.placeholder.com/120x120?text=No+Image" // Tampilkan placeholder jika gambar gagal dimuat
                          />
                        </div>
                      ) : null
                    )}
                </div>
              </Form.Item>
            </div>

            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"QRCODE"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <div
                  ref={qrCodeRef}
                  style={{
                    position: "relative",
                    width: "9cm",
                    height: "6cm",
                    border: "1px solid black",
                    borderRadius: "8px",
                    backgroundColor: "#112d63",
                    color: "white",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  {/* QR Code + Text */}
                  <div style={{ textAlign: "center" }}>
                    <QRCodeCanvas
                      value={dataHeader.no_identifikasi || "DEFAULT"}
                      size={150}
                      style={{
                        backgroundColor: "white",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    />
                    {/* Text Identifikasi */}
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        textAlign: "center",
                        display: "block",
                        marginTop: "10px",
                      }}>
                      {dataHeader.no_identifikasi || ""}
                    </span>
                  </div>

                  {/* Logo Bea Cukai */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "150px",
                      height: "150px",
                      borderRadius: "5px",
                    }}>
                    <img
                      src={logoBeaCukai}
                      alt="Bea Cukai Logo"
                      style={{
                        marginBottom: "30px",
                        width: "100px",
                      }}
                    />
                  </div>
                </div>
                {/* Tombol Cetak */}
                <button
                  style={{
                    marginTop: "10px",
                    padding: "5px 15px",
                    backgroundColor: isPrinting ? "#ccc" : "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: isPrinting ? "not-allowed" : "pointer",
                  }}
                  onClick={printQRCode}
                  disabled={isPrinting}>
                  {isPrinting ? "Mencetak..." : "Cetak QR Code"}
                </button>
              </Form.Item>
            </div>
          </div>
        </Card>
      </div>
      <div style={{ display: "none" }}>
        <Image.PreviewGroup
          preview={{
            visible, // Status kontrol preview
            onVisibleChange: (vis) => setVisible(vis), // Perbarui status ketika ditutup
          }}>
          {dataHeader &&
            dataHeader.foto_mesin &&
            Array.isArray(dataHeader.foto_mesin) &&
            dataHeader.foto_mesin.map((foto, index) => <Image src={foto.url_foto} key={index} />)}
        </Image.PreviewGroup>
      </div>
    </Fragment>
  );
}
export default DetilMesin;
