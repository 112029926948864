import React, { useEffect, useState } from "react";
import { Row, Col, Dropdown, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Chart from "react-apexcharts";
import AOS from "aos";
import "aos/dist/aos.css";

// Actions
import {
  NavbarstyleAction,
  SchemeDirAction,
  SidebarColorAction,
  SidebarActiveStyleAction,
  ColorCustomizerAction,
  getDarkMode,
  getcustomizerMode,
  getcustomizerprimaryMode,
  getcustomizerinfoMode,
  getDirMode,
  getSidebarColorMode,
  getSidebarActiveMode,
  getNavbarStyleMode,
} from "../../store/setting/setting";

// Konfigurasi chart
const chartConfig = {
  chart1: {
    options: {
      chart: {
        toolbar: { show: false },
        sparkline: { enabled: false },
      },
      colors: [],
      dataLabels: { enabled: false },
      stroke: { curve: "smooth", width: 3 },
      yaxis: {
        labels: {
          style: { colors: "#8A92A6" },
          offsetX: -5,
        },
      },
      xaxis: {
        labels: { style: { colors: "#8A92A6" } },
        categories: ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Aug", "Sept", "Okt", "Nov", "Des"],
      },
      fill: {
        gradient: {
          shade: "dark",
          type: "vertical",
          opacityFrom: 0.4,
          opacityTo: 0.1,
          stops: [0, 50, 80],
        },
      },
    },
    series: [
      { name: "Lembar CK-1", data: [0] },
      { name: "Lembar CK-1", data: [0] },
    ],
  },
};

const mapStateToProps = (state) => ({
  darkMode: getDarkMode(state),
  customizerMode: getcustomizerMode(state),
  cololrinfomode: getcustomizerinfoMode(state),
  colorprimarymode: getcustomizerprimaryMode(state),
  schemeDirMode: getDirMode(state),
  sidebarcolorMode: getSidebarColorMode(state),
  sidebaractivestyleMode: getSidebarActiveMode(state),
  navbarstylemode: getNavbarStyleMode(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      NavbarstyleAction,
      SchemeDirAction,
      SidebarColorAction,
      SidebarActiveStyleAction,
      ColorCustomizerAction,
    },
    dispatch
  );

const Index = (props) => {
  const history = useHistory();
  const [user, setUser] = useState(null);

  const token = localStorage.getItem("token");
  const username = localStorage.getItem("username");

  const fetchData = async () => {
    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      const response = await axios.get(`https://one-delta-api-1020394607917.asia-southeast2.run.app/user/profil/mobile?username=${username}`);
      const userData = response.data.body.data.data[0];

      localStorage.setItem("nama_identitas", userData.nama_identitas);
      localStorage.setItem("kode_kantor", userData.kode_kantor);

      const userLevel = userData.id_otoritas === "1" ? "Admin" : userData.id_otoritas === "2" ? "Pemeriksa Dokumen" : "Pengguna Jasa";
      localStorage.setItem("user_level", userLevel);
      console.log("RESPONa: ", userData.id_otoritas);

      setUser(userData.nama_identitas);
    } catch (error) {
      console.error("Error fetching user data", error);
    }
  };

  useEffect(() => {
    if (!token) history.push("/");
    else fetchData();
  }, [token]);

  useEffect(() => {
    AOS.init({
      startEvent: "DOMContentLoaded",
      disable: () => window.innerWidth < 996,
      throttleDelay: 10,
      once: true,
      duration: 700,
      offset: 10,
    });

    const colorcustomizerMode = sessionStorage.getItem("color-customizer-mode");
    if (colorcustomizerMode === null) {
      props.ColorCustomizerAction(props.customizerMode, props.cololrinfomode, props.colorprimarymode);
    } else {
      props.ColorCustomizerAction(
        colorcustomizerMode,
        sessionStorage.getItem("colorcustominfo-mode"),
        sessionStorage.getItem("colorcustomprimary-mode")
      );
    }
  }, [props]);

  return (
    <Row>
      <Col md="12" lg="12">
        <div className="card" data-aos="fade-up" data-aos-delay="800">
          <div className="d-flex justify-content-between align-items-center card-header">
            <div>
              <h4 className="card-title">PERBANDINGAN CK-1</h4>
              <p className="mb-0">Tahun (X) dengan Tahun (X-1)</p>
            </div>
            <Dropdown>
              <Dropdown.Toggle variant="secondary">Pilih Tahun</Dropdown.Toggle>
              <Dropdown.Menu>
                {["2023", "2022", "2021"].map((year) => (
                  <Dropdown.Item key={year}>{year}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="card-body">
            <Chart options={chartConfig.chart1.options} series={chartConfig.chart1.series} type="area" height="245" />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
