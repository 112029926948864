import React, { useState, useEffect, Fragment } from "react";
import { Card, Form, Input, Table } from "antd";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";

function DetilCk3HT(props) {
  const [dataHeader, setDataHeader] = useState([""]);
  const idCK = props.idCK;
  console.log("idCK: ", props.idCK);

  useEffect(() => {
    const getDataHeader = () => {
      axios
        .get("https://one-delta-api-1020394607917.asia-southeast2.run.app/ck3/detil?id=" + idCK, {
          headers: {},
        })
        .then((res) => {
          const result = res.data.body.data.data;
          setDataHeader(result);
          // console.log("HEADER: ", result);
        })
        .catch((error) => {});
    };
    getDataHeader();
  }, [idCK]);

  console.log("HEADER BAWAH: ", dataHeader[0].npwp);

  const tbl_detil = [
    {
      title: "MERK",
      width: 270,
      key: "nama_merk",
      dataIndex: "nama_merk",
      fixed: "left",
    },
    {
      title: "JENIS PRODUKSI",
      width: 150,
      key: "jenis_produksi",
      dataIndex: "jenis_produksi",
    },
    {
      title: "ISI",
      key: "isi",
      dataIndex: "isi",
      width: 100,
    },
    {
      title: "HJE",
      key: "hje",
      dataIndex: "hje",
      width: 180,
      render: (hje) => (
        <span>
          {hje
            ? hje.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              })
            : "Rp. -"}
        </span>
      ),
    },
    {
      title: "TARIF",
      key: "tarif",
      dataIndex: "tarif",
      width: 180,
      render: (tarif) => (
        <span>
          {tarif
            ? tarif.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              })
            : "Rp. -"}
        </span>
      ),
    },
    {
      title: "SERI PITA",
      key: "seri_pita_golongan",
      dataIndex: "seri_pita_golongan",
      width: 150,
    },
    {
      title: "TAHUN",
      key: "tahun_pita",
      dataIndex: "tahun_pita",
    },
    {
      title: "KEPING PBCK-4",
      key: "jumlah_keping_pbck4",
      dataIndex: "jumlah_keping_pbck4",
      render: (jumlah_keping_pbck4) => (jumlah_keping_pbck4 ? jumlah_keping_pbck4.toLocaleString("id-ID") : "-"),
    },
    {
      title: "CUKAI PBCK-4",
      key: "jumlah_cukai_pbck4",
      dataIndex: "jumlah_cukai_pbck4",
      render: (jumlah_cukai_pbck4) => (
        <span>
          {jumlah_cukai_pbck4
            ? jumlah_cukai_pbck4.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              })
            : "Rp. -"}
        </span>
      ),
    },

    {
      title: "KEPING CK3",
      key: "jumlah_keping_ck3",
      dataIndex: "jumlah_keping_ck3",
      render: (jumlah_keping_ck3) => (jumlah_keping_ck3 ? jumlah_keping_ck3.toLocaleString("id-ID") : "-"),
    },

    // {
    //   title: "KETERANGAN",
    //   key: "keterangan",
    //   dataIndex: "keterangan",
    //   render: (keterangan) => (keterangan ? keterangan.toLocaleString("id-ID") : "-"),
    // },
  ];

  return (
    <Fragment>
      <div>
        <Card title="DATA HEADER">
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NPPBKC"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nppbkc ? `${dataHeader[0].nppbkc}` : "-"} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nama Perusahaan"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nama_perusahaan ? `${dataHeader[0].nama_perusahaan}` : "-"} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor/ Tanggal PBCK-4"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={
                    dataHeader[0].nomor_pbck4 && dataHeader[0].tanggal_pbck4
                      ? `${dataHeader[0].nomor_pbck4} / ${moment(dataHeader[0].tanggal_pbck4).format("DD-MM-YYYY")}`
                      : "- / -"
                  }
                />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Tanggal Pemeriksaan"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={dataHeader[0].tanggal_pemeriksaan ? `${moment(dataHeader[0].tanggal_pemeriksaan).format("DD-MM-YYYY")}` : "-"}
                />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor / Tanggal BACK-1"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={
                    dataHeader[0].nomor_back1 && dataHeader[0].tanggal_back1
                      ? `${dataHeader[0].nomor_back1} / ${moment(dataHeader[0].tanggal_back1).format("DD-MM-YYYY")}`
                      : "- / -"
                  }
                />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor/ Tanggal Pendapat"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={
                    dataHeader[0].nomor_pendapat && dataHeader[0].tanggal_pendapat
                      ? `${dataHeader[0].nomor_pendapat} / ${moment(dataHeader[0].tanggal_pendapat).format("DD-MM-YYYY")}`
                      : "- / -"
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor/ Tanggal Pengantar"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={
                    dataHeader[0].nomor_pengantar && dataHeader[0].tanggal_pengantar
                      ? `${dataHeader[0].nomor_pengantar} / ${moment(dataHeader[0].tanggal_pengantar).format("DD-MM-YYYY")}`
                      : "- / -"
                  }
                />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor / Tanggal BA Pusat"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={
                    dataHeader[0].nomor_ba_pusat && dataHeader[0].tanggal_ba_pusat
                      ? `${dataHeader[0].nomor_ba_pusat} / ${moment(dataHeader[0].tanggal_ba_pusat).format("DD-MM-YYYY")}`
                      : "- / -"
                  }
                />
              </Form.Item>
            </div>
            {/* <div className="col-lg-6 col-md-9 col-sm-12">
                    <Form.Item label={"Keterangan"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                      <Input disabled value={dataHeader[0].nppbkc} />
                      <Input disabled value={moment(dataHeader[0].tanggal_pppc).format("DD-MM-YYYY")} />
                    </Form.Item>
                  </div> */}
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor/ Tanggal CK-3"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={
                    dataHeader[0].nomor_ck3 && dataHeader[0].tanggal_ck3
                      ? `${dataHeader[0].nomor_ck3} / ${moment(dataHeader[0].tanggal_ck3).format("DD-MM-YYYY")}`
                      : "- / -"
                  }
                />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor/ Tanggal Keputusan"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={
                    dataHeader[0].nomor_keputusan && dataHeader[0].tanggal_keputusan
                      ? `${dataHeader[0].nomor_keputusan} / ${moment(dataHeader[0].tanggal_keputusan).format("DD-MM-YYYY")}`
                      : "- / -"
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Tanggal Lunas BP"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].tanggal_lunas_bp ? `${moment(dataHeader[0].tanggal_lunas_bp).format("DD-MM-YYYY")}` : "-"} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Status"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].status ? `${dataHeader[0].status}` : "-"} />
              </Form.Item>
            </div>
          </div>
        </Card>
        <Card title="RINCIAN">
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <Table
              dataSource={dataHeader[0].detil}
              columns={tbl_detil}
              scroll={{
                x: 1800,
              }}
            />
          </div>
        </Card>
      </div>
    </Fragment>
  );
}
export default DetilCk3HT;
