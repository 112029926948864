import React, { useState, useRef, Fragment, useEffect } from "react";
import { Card, Form, Input, Button, Select, Upload, message } from "antd";
import axios from "axios";
import swal from "sweetalert";
import { UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { data } from "jquery";

function Edit(props) {
  const token = localStorage.getItem("token");
  const username = localStorage.getItem("username");
  const { Option } = Select;
  const inputRef = useRef(null);
  const [ImageUpload, setImageUpload] = useState();
  const [tipeMesin, setTipeMesin] = useState([""]);
  const [jenis_mesin, setjenis_mesin] = useState([""]);
  const [merkMesin, setMerkMesin] = useState([""]);
  const [dataNppbkc, setDataNppbkc] = useState([""]);
  const [nppbkc, setNppbkc] = useState("");
  const [nama_perusahaan, setNama_Perusahaan] = useState("");
  const [alamat, setAlamat] = useState("");
  const [location, setLocation] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dataHeader, setDataHeader] = useState([""]);
  const id_mesin = props.id_mesin;

  console.log("id_mesin DetilMesin: ", props.id_mesin);

  useEffect(() => {
    const getDataHeader = () => {
      axios
        .get("https://one-delta-api-1020394607917.asia-southeast2.run.app/mesin/detil-mesin?id=" + id_mesin, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: token,
          },
        })
        .then((res) => {
          const result = res.data.body.data.data[0];
          console.log("getDataHeader: ", result);
          setDataHeader(result);
        })
        .catch((error) => {});
    };

    getDataHeader();
  }, [id_mesin]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          const latlng = lat + "," + lng;
          setLocation(latlng);

          // Mengambil data alamat menggunakan Geocoding API
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, [location]);

  //

  const getDataMerk = () => {
    axios
      .get("https://one-delta-api-1020394607917.asia-southeast2.run.app/mesin/referensi/merk-mesin", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
        },
      })
      .then((res) => {
        const result = res.data.body.data.data;
        setMerkMesin(result);
      })
      .catch((error) => {});
  };
  // Get Data Tipe Mesin
  const getDataTipeMesin = () => {
    axios
      .get("https://one-delta-api-1020394607917.asia-southeast2.run.app/mesin/referensi/tipe-mesin", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
        },
      })
      .then((res) => {
        const result = res.data.body.data.data;
        setTipeMesin(result);
      })
      .catch((error) => {});
  };

  // Get Data Jenis Produksi
  const getDataJenisProduksi = () => {
    axios
      .get("https://one-delta-api-1020394607917.asia-southeast2.run.app/mesin/referensi/jenisproduksi-mesin", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
        },
      })
      .then((res) => {
        const result = res.data.body.data.data;
        setjenis_mesin(result);
      })
      .catch((error) => {});
  };

  const onFinish = (values) => {
    const formData = new FormData();
    formData.append(
      "mesinDetilPojo",
      JSON.stringify({
        alamat: dataHeader.alamat,
        idJenisProduksi: values.idJenisProduksi,
        id_merk: values.merkMesin,
        idTipe: values.idTipe,
        jenis_mesin: values.jenis_mesin,
        kapasitas: values.kapasitasMesin,
        kepemilikkan: values.statuskepemilikkan,
        keterangan: values.keterangan,
        kondisi: values.kondisiMesin,
        longlat: location,
        namaMerk: values.merkMesin,
        nama_perusahaan: dataHeader.nama_perusahaan,
        negaraPembuat: values.negara,
        // no_identifikasi: "12345",
        nppbkc: dataHeader.nppbkc,
        serial_number: values.serial_number,
        tahunPembuatan: values.tahunPembuatan,
        userRekam: username,
        userUpdate: username,
      })
    );
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("images", selectedFiles[i]);
    }
    // formData.append("image", ImageUpload);

    const options = {
      method: "POST",
      url: "https://one-delta-api-1020394607917.asia-southeast2.run.app/mesin/simpan-mesin",
      data: formData,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    };
    console.log(formData);
    axios(options)
      .then(function (response) {
        console.log(response);
        swal({
          title: "Selamat!",
          text: "Berhasil Rekam Mesin!",
          icon: "success",
        }).then((value) => {});
      })
      .catch(function (error) {
        message.error("Gagal simpan data");
        console.log(error);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  useEffect(() => {
    // getDataHeader();
    getDataJenisProduksi();
    getDataTipeMesin();
    getDataMerk();
    console.log("DATA: ", dataHeader);
  }, []);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleCameraCapture = () => {
    inputRef.current.click();
  };

  const handleRemoveImage = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  return (
    <Fragment>
      <div>
        <Card>
          <Form
            name="basic"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 24 }}
            // style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <Card title="Data Perusahaan">
              <Form.Item label="NPPBKC" name="nppbkc">
                <Input disabled value={dataHeader.nppbkc} />
                <Input type="hidden" disabled value={dataHeader.nppbkc} />
              </Form.Item>

              <Form.Item label="Nama Perusahaan" name="nama_perusahaan">
                <Input disabled value={dataHeader.nama_perusahaan} />
                <Input hidden value={dataHeader.nama_perusahaan} />
              </Form.Item>

              <Form.Item label="Alamat" name="alamat">
                <Input disabled value={dataHeader.alamat_perusahaan} />
                <Input hidden value={dataHeader.alamat_perusahaan} />
              </Form.Item>
            </Card>
            <Card title="Data Mesin">
              <Form.Item
                label="Merk Mesin"
                name="merkMesin"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select>
                  {merkMesin.map((merk) => {
                    return (
                      <Option key={merk.id_merk} value={merk.id_merk}>
                        {merk.nama}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Jenis Mesin"
                name="jenis_mesin"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select>
                  <Option value={"Pelinting"}>Pelinting</Option>
                  <Option value={"Pengemas"}>Pengemas</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Serial Number"
                name="serial_number"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Input />
              </Form.Item>

              <Form.Item
                label="Tipe Mesin"
                name="idTipe"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select>
                  {tipeMesin.map((tipe) => {
                    return (
                      <Option key={tipe.idTipeMesin} value={tipe.idTipeMesin}>
                        {tipe.nama}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Jenis Produksi"
                name="idJenisProduksi"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select>
                  {jenis_mesin.map((jenis) => {
                    return (
                      <Option key={jenis.idJenisProduksi} value={jenis.idJenisProduksi}>
                        {jenis.nama}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item label="Tahun Pembuatan" name="tahunPembuatan">
                <Input type="number" />
              </Form.Item>

              <Form.Item
                label="Negara"
                name="negara"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Input />
              </Form.Item>

              <Form.Item label="Kapasitas Mesin" name="kapasitasMesin">
                <Input type="number" />
              </Form.Item>

              <Form.Item
                label="Kondisi Mesin"
                name="kondisiMesin"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select>
                  <Option value={"Baru"}>Baru</Option>
                  <Option value={"Bekas"}>Bekas</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Status kepemilikkan"
                name="statuskepemilikkan"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select>
                  <Option value={"Milik Sendiri"}>Milik Sendiri</Option>
                  <Option value={"Sewa"}>Sewa</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Keterangan"
                name="keterangan"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <TextArea rows={2} />
              </Form.Item>
              <Form.Item>
                <div className="form-group basic">
                  <input type="file" accept="image/*" multiple onChange={handleFileChange} style={{ display: "none" }} ref={inputRef} />
                  <div className="selected-images">
                    {selectedFiles.map((file, index) => (
                      <div key={index} className="image-container">
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`Image ${index + 1}`}
                          className="image-preview"
                          style={{
                            width: "200px",
                            height: "200px",
                            objectFit: "cover",
                            display: "block",
                            margin: "0 auto",
                          }}
                        />
                        <br />
                        <Button
                          size="small"
                          type="primary"
                          danger="true"
                          block
                          onClick={() => handleRemoveImage(index)}
                          style={{
                            marginLeft: "100px",
                            objectFit: "cover",
                            display: "block",
                            margin: "0 auto",
                          }}>
                          -Delete Photo-
                        </Button>
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
              </Form.Item>
              <Card>
                <Form.Item wrapperCol={{ offset: 0, span: 3, margintop: 80 }}>
                  <Button size="medium" type="primary" block onClick={handleCameraCapture}>
                    AMBIL FOTO
                  </Button>
                </Form.Item>
              </Card>
            </Card>
            <Card>
              <Form.Item wrapperCol={{ offset: 0, span: 3, margintop: 80 }}>
                <Button size="large" type="primary" htmlType="submit" block>
                  Submit
                </Button>
              </Form.Item>
            </Card>
          </Form>
        </Card>
      </div>
    </Fragment>
  );
}
export default Edit;
