import React, { useState, useEffect } from "react";
import { Form, Input, Select, Col, Row, Checkbox, Card, DatePicker, Button, Table, message } from "antd";
import axios from "axios";
// import TextArea from "antd/es/input/TextArea";
// import moment from "moment";
import swal from "sweetalert";
const { Option } = Select;
function FormA(props) {
  const [dataHeader, setDataHeader] = useState([""]);
  const [dataOption, setDataOption] = useState([""]);
  const nppbkc = props.nppbkc;
  console.log("nppbkc: ", nppbkc);

  useEffect(() => {
    const getDataHeader = () => {
      axios
        .get("https://one-delta-api-1020394607917.asia-southeast2.run.app/registrasi/detil/reg-A?nppbkc=" + nppbkc, {
          headers: {},
        })
        .then((res) => {
          const result = res.data.body.data.data;
          setDataHeader(result);
          // console.log("HEADER: ", result);
        })
        .catch((error) => {});
    };
    const getDataOptions = () => {
      axios
        .get("https://one-delta-api-1020394607917.asia-southeast2.run.app/registrasi/referensi/dok-perijinan", {
          headers: {},
        })
        .then((res) => {
          const result = res.data.body.data.data;
          setDataOption(result);
          // console.log("HEADER: ", result);
        })
        .catch((error) => {});
    };
    getDataHeader();
    getDataOptions();
  }, [nppbkc]);

  const onFinish = (values) => {
    const formData = new FormData();
    formData.append(
      "detils",
      JSON.stringify({
        akhirBerlaku: values.akhirBerlaku,
        idJenisDokumen: "",
        idRegA: "",
        idRegAPendukung: "",
        nomorDokumen: values.nomorDokumen,
        tanggalDokumen: "string",
      })
    );

    formData.append("flagFinal", dataHeader[0].flag_final);
    formData.append("idRegA", "");
    formData.append("idRegistrasi", "");
    formData.append("jenisBkc", dataHeader[0].jenis_bkc);
    formData.append("jenisProduksi", values.jenisProduksi);
    formData.append("jenisUsaha", dataHeader[0].jenis_usaha);
    formData.append("nama_perusahaan", dataHeader[0].nama_perusahaan);
    formData.append("nomorBap", values.nomorBap);
    formData.append("nppbkc", dataHeader[0].nppbkc);
    formData.append("npwp", dataHeader[0].npwp);
    formData.append("skepNppbkc", dataHeader[0].skep_nppbkc);
    formData.append("status", values.status);
    formData.append("tanggalBap", values.tanggalBap);
    formData.append("tanggalNppbkc", values.tanggalNppbkc);

    const options = {
      method: "POST",
      url: "https://one-delta-api-1020394607917.asia-southeast2.run.app/registrasi/simpan-reg-A",
      data: formData,
      headers: {
        "Access-Control-Allow-Origin": "*",
        // "Content-Type": "multipart/form-data",
      },
    };
    console.log(formData);
    axios(options)
      .then(function (response) {
        console.log(response);
        swal({
          title: "Selamat!",
          text: "Berhasil Rekam Mesin!",
          icon: "success",
        }).then((value) => {});
      })
      .catch(function (error) {
        message.error("Gagal simpan data");
        console.log(error);
      });
  };

  // const onClickDok = (values) => {
  //   const formDok = new FormData();
  //   formDok.append(
  //     "detils",
  //     JSON.stringify({
  //       akhirBerlaku: values.akhirBerlaku,
  //       idJenisDokumen: "",
  //       idRegA: "",
  //       idRegAPendukung: "",
  //       nomorDokumen: values.nomorDokumen,
  //       tanggalDokumen: "string",
  //     })
  //   );
  //   const options = {
  //     method: "POST",
  //     url: "https://one-delta-api-1020394607917.asia-southeast2.run.app/registrasi/simpan-reg-A",
  //     data: formDok,
  //     headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       // "Content-Type": "multipart/form-data",
  //     },
  //   };
  //   console.log(formDok);
  //   axios(options)
  //     .then(function (response) {
  //       console.log(response);
  //       swal({
  //         title: "Selamat!",
  //         text: "Berhasil Rekam Mesin!",
  //         icon: "success",
  //       }).then((value) => {});
  //     })
  //     .catch(function (error) {
  //       message.error("Gagal simpan data");
  //       console.log(error);
  //     });
  // };

  const tbl_formA = [
    {
      title: "JENIS USAHA",
      width: 100,
      key: "jenis_usaha",
      dataIndex: "jenis_usaha",
    },
    {
      title: "NOMOR",
      key: "skep_nppbkc",
      dataIndex: "skep_nppbkc",
      width: 100,
    },
    {
      title: "TANGGAL",
      key: "tanggal_nppbkc",
      dataIndex: "tanggal_nppbkc",
      width: 100,
    },
  ];
  return (
    <div>
      <Card title="Perusahaan">
        <Form
          name="basic"
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 24 }}
          // style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off">
          <Input hidden value={dataHeader[0].skep_nppbkc} />
          <Input hidden value={dataHeader[0].status} />
          <Input hidden value={dataHeader[0].flag_final} />
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="NPPBKC">
                <Input disabled value={dataHeader[0].nppbkc} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Nama Perusahaan">
                <Input disabled value={dataHeader[0].nama_perusahaan} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="NPWP">
                <Input disabled value={dataHeader[0].npwp} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Jenis Usaha">
                <Input disabled value={dataHeader[0].jenis_usaha} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Jenis BKC">
                <Input disabled value={dataHeader[0].jenis_bkc} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Jenis HT">
                <Checkbox.Group style={{ width: "100%" }} name="jenisProduksi">
                  <Row>
                    <Col span={8}>
                      <Checkbox value="1">SKT</Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="2">SKM</Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="3">SPT</Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="4">SPM</Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="5">CRT</Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="6">TIS</Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="7">KLB</Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="8">KLM</Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="9">SKTF</Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="10">SPTF</Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="11">REL</Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="12">HTL</Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>

      <Card title="Data Pendukung">
        <Form>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Nomor BAP Lokasi" name="nomorBap">
                <Input placeholder="Nomor BAP Lokasi" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Tanggal BAP Lokasi" name="tanggalBap">
                <DatePicker placeholder="Tanggal BAP Lokasi" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Dokumen Perijinan"></Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Jenis Dokumen">
                <Select>
                  <Option value="99">Hard</Option>
                  <Option key={dataOption[0].idDokumenPerijinan} value={dataOption[0].idDokumenPerijinan}>
                    {dataOption[0].jenisDokumen}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Nomor Dokumen" name="nomorDokumen">
                <Input placeholder="Nomor Dokumen" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Tanggal Dokumen" name="tanggalDokumen">
                <DatePicker placeholder="Tanggal Dokumen" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Akhir Berlaku" name="akhirBerlaku">
                <DatePicker placeholder="Akhir Berlaku" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className="form-group form-group-xs row">
          <Form.Item wrapperCol={{ offset: 0, span: 3, margintop: 80 }}>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>

          <div className="col-3"></div>
        </div>
      </Card>

      <Table dataSource={dataHeader} columns={tbl_formA} />
      <div className="form-group form-group-xs row">
        <label className="col-2 col-form-label"></label>
        <label className="col-2 col-form-label"></label>
        <label className="col-1 col-form-label"></label>
        <div className="col-3">
          <Button type="primary" htmlType="submit" block>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FormA;
