import React, { Fragment, Component } from "react";
import moment from "moment";
import { Row, Col, Card, Table, Upload, Form, Button, Drawer, message, Input, Space } from "antd";
import DetilDppc from "./detil";
import axios from "axios";
import SweetAlert from "sweetalert2";
import Highlighter from "react-highlight-words";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
const screenWidth = window.innerWidth;
const drawerWidth = screenWidth * 0.8;

class Dppc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasil: [],
      pagination: {},
      loading: false,
      loadingSpin: true,
      showDetil: false,
    };
  }

  fetch = async () => {
    this.setState({
      loading: true,
    });
    axios
      .get("https://one-delta-api-1020394607917.asia-southeast2.run.app/dppc/browse", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((data) => {
        const pagination = {
          ...this.state.pagination,
        };
        pagination.total = data.data.body.data.length;
        console.log(">>>>", data.data.body.data.data);

        this.setState({
          hasil: data.data.body.data.data,
          pagination,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err.message);

        this.setState({
          loading: false,
          hasil: [],
          pagination: false,
        });
        SweetAlert.fire({
          title: "Oops...",
          text: "Data tidak ditemukan!",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  handleOnClose = () => {
    this.setState({
      showDetil: false,
    });
  };

  detilDppc = (idDppc) => {
    this.setState({
      idDppc: idDppc,
      showDetil: true,
    });
  };

  handleTableChange = (pagination, filters) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      loading: true,
    });
    this.fetch({
      size: pagination.pageSize,
      page: pagination.current,
      ...filters,
    });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({
      searchText: "",
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{
              width: 90,
            }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ""),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  componentDidMount() {
    this.fetch();
  }

  handleUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("https://one-delta-api-1020394607917.asia-southeast2.run.app/ck1/import-excel", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      message.success("File berhasil diupload!");
      this.fetch(); // Refresh tabel setelah upload
    } catch (error) {
      console.error(error);
      message.error("Gagal upload file. Silakan coba lagi.");
    }
  };

  render() {
    const uploadProps = {
      name: "file",
      multiple: false,
      beforeUpload: (file) => {
        const isExcel = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel";
        if (!isExcel) {
          message.error("Hanya file Excel yang diizinkan!");
        }
        return isExcel || Upload.LIST_IGNORE;
      },
      customRequest: ({ file }) => {
        this.handleUpload(file);
      },
    };

    const dppc = [
      {
        title: "NPPBKC",
        width: 150,
        key: "nppbkc",
        dataIndex: "nppbkc",
        fixed: "left",
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("nppbkc"),
      },
      {
        title: "PERUSAHAAN",
        key: "nama_perusahaan",
        dataIndex: "nama_perusahaan",
        width: 300,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("nama_perusahaan"),
      },
      {
        title: "NO DPPC",
        key: "nomorDppc",
        dataIndex: "nomorDppc",
        width: 110,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("nomorPppc"),
      },
      {
        title: "TANGGAL DPPC",
        key: "tanggalDppc",
        dataIndex: "tanggalDppc",
        width: 160,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("tanggalDppc"),
        render: (text) => {
          return moment(text).format("DD-MM-YYYY");
        },
      },
      {
        title: "TANGGAL TERIMA",
        key: "tanggalTerima",
        dataIndex: "tanggalTerima",
        sortDirections: ["descend", "ascend"],
        width: 170,
        render: (text) => {
          return moment(text).format("DD-MM-YYYY");
        },
      },

      {
        title: "PENERIMA",
        key: "penerima",
        dataIndex: "penerima",
      },
      {
        title: "JENIS BKC",
        key: "jenisBkc",
        dataIndex: "jenisBkc",
        // sortDirections: ["descend", "ascend"],
        // ...this.getColumnSearchProps("status"),
      },
      {
        title: "STATUS",
        key: "status",
        dataIndex: "status",
        // sortDirections: ["descend", "ascend"],
        // ...this.getColumnSearchProps("status"),
      },
      {
        title: "#AKSI",
        key: "idDppc",
        dataIndex: "idDppc",
        width: 100,
        fixed: "right",
        render: (text, row) => (
          <span>
            {row.idDppc
              .toString()
              .split(",")
              .map((idDppc) => {
                let button = (
                  <Button type="primary" size="medium" onClick={() => this.detilDppc(row.idDppc)}>
                    <i className="fa fa-arrow-right" />
                  </Button>
                );
                return <span> {button} </span>;
              })}
          </span>
        ),
      },
    ];

    return (
      <Fragment>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card bordered={false} className="criclebox tablespace mb-24" title="BROWSE DATA DPPC">
                <div style={{ marginBottom: 16, textAlign: "right" }}>
                  <Upload {...uploadProps}>
                    <Button icon={<UploadOutlined />} type="primary">
                      Upload Excel
                    </Button>
                  </Upload>
                </div>
                <div className="table-responsive">
                  <Form>
                    <Table
                      columns={dppc}
                      dataSource={this.state.hasil}
                      pagination={this.state.pagination}
                      loading={this.state.loading}
                      onChange={this.handleTableChange}
                      scroll={{
                        x: 1500,
                      }}
                    />
                  </Form>
                </div>
              </Card>
            </Col>
          </Row>
          <Drawer title={"DETIL DATA DPPC"} placement="right" width={drawerWidth} open={this.state.showDetil} onClose={this.handleOnClose}>
            <DetilDppc idDppc={this.state.idDppc} />
          </Drawer>
        </div>
      </Fragment>
    );
  }
}

export default Dppc;
