import React, { Fragment, Component } from "react";
import moment from "moment";
import { Row, Col, Card, Table, Input, Button, Drawer, Space, Upload, message } from "antd";
import DetilNppbkc from "./detil";
import axios from "axios";
import SweetAlert from "sweetalert2";
import Highlighter from "react-highlight-words";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";

const screenWidth = window.innerWidth;
const drawerWidth = screenWidth * 0.8;

class Nppbkc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasil: [],
      pagination: {},
      loading: false,
      showDetilNppbkc: false,
      searchText: "",
      searchedColumn: "",
      nppbkc: "",
    };
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    this.setState({ loading: true });
    axios
      .get("https://one-delta-api-1020394607917.asia-southeast2.run.app/nppbkc/browse", {
        headers: { "Access-Control-Allow-Origin": "*" },
      })
      .then((data) => {
        const pagination = { ...this.state.pagination };
        pagination.total = data.data.body.data.length;

        this.setState({
          hasil: data.data.body.data.data,
          pagination,
          loading: false,
        });
      })
      .catch((err) => {
        console.error(err.message);
        this.setState({
          loading: false,
          hasil: [],
          pagination: false,
        });
        SweetAlert.fire({
          title: "Oops...",
          text: "Data tidak ditemukan!",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  handleOnClose = () => {
    this.setState({ showDetilNppbkc: false });
  };

  detilNppbkc = (nppbkc) => {
    this.setState({
      nppbkc: nppbkc,
      showDetilNppbkc: true,
    });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ""),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("https://one-delta-api-1020394607917.asia-southeast2.run.app/ck1/import-excel", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      message.success("File berhasil diupload!");
      this.fetch(); // Refresh tabel setelah upload
    } catch (error) {
      console.error(error);
      message.error("Gagal upload file. Silakan coba lagi.");
    }
  };

  render() {
    const uploadProps = {
      name: "file",
      multiple: false,
      beforeUpload: (file) => {
        const isExcel = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel";
        if (!isExcel) {
          message.error("Hanya file Excel yang diizinkan!");
        }
        return isExcel || Upload.LIST_IGNORE;
      },
      customRequest: ({ file }) => {
        this.handleUpload(file);
      },
    };

    const columns = [
      {
        title: "NPPBKC",
        key: "nppbkc",
        dataIndex: "nppbkc",
        ...this.getColumnSearchProps("nppbkc"),
      },
      {
        title: "PERUSAHAAN",
        key: "namaPerusahaan",
        dataIndex: "namaPerusahaan",
        ...this.getColumnSearchProps("namaPerusahaan"),
      },
      {
        title: "PEMILIK",
        key: "pemilik",
        dataIndex: "pemilik",
        ...this.getColumnSearchProps("pemilik"),
      },
      {
        title: "TANGGAL NPPBKC",
        key: "tanggalNppbkc",
        dataIndex: "tanggalNppbkc",
        render: (text) => moment(text).format("DD-MM-YYYY"),
      },
      {
        title: "#AKSI",
        key: "aksi",
        dataIndex: "nppbkc",
        render: (text, row) => (
          <Button type="primary" onClick={() => this.detilNppbkc(row.nppbkc)}>
            Detil
          </Button>
        ),
      },
    ];

    return (
      <Fragment>
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="tablespace mb-24" title="BROWSE DATA NPPBKC">
              <div style={{ marginBottom: 16, textAlign: "right" }}>
                <Upload {...uploadProps}>
                  <Button icon={<UploadOutlined />} type="primary">
                    Upload Excel
                  </Button>
                </Upload>
              </div>
              <Table
                columns={columns}
                dataSource={this.state.hasil}
                pagination={this.state.pagination}
                loading={this.state.loading}
                rowKey={(record) => record.nppbkc}
              />
            </Card>
          </Col>
        </Row>
        <Drawer title="DETIL DATA NPPBKC" placement="right" width={drawerWidth} open={this.state.showDetilNppbkc} onClose={this.handleOnClose}>
          <DetilNppbkc nppbkc={this.state.nppbkc} />
        </Drawer>
      </Fragment>
    );
  }
}

export default Nppbkc;
