import React, { useState, useEffect, Fragment } from "react";
import { Card, Form, Input, Table } from "antd";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";

function DetilMMEANonPita(props) {
  const [dataHeader, setDataHeader] = useState([""]);
  const idCK = props.idCK;
  console.log("idCK Non PITA: ", props.idCK);

  useEffect(() => {
    const getDataHeader = () => {
      axios
        .get("https://one-delta-api-1020394607917.asia-southeast2.run.app/ck1/detil?id=" + idCK, {
          headers: {},
        })
        .then((res) => {
          const result = res.data.body.data.data;
          setDataHeader(result);
          // console.log("HEADER: ", result);
        })
        .catch((error) => {});
    };
    getDataHeader();
  }, [idCK]);

  const tbl_mmea = [
    {
      title: "MEREK",
      width: 150,
      key: "jenis_produksi",
      dataIndex: "jenis_produksi",
      fixed: "left",
    },
    {
      title: "JENIS KEMASAN",
      key: "jenis_produksi",
      dataIndex: "jenis_produksi",
      width: 150,
    },
    {
      title: "VOLUME (ML)",
      key: "isi",
      dataIndex: "isi",
      width: 100,
    },
    {
      title: "VOLUME (L)",
      key: "isi",
      dataIndex: "isi",
      width: 100,
    },

    {
      title: "TARIF",
      key: "tarif",
      dataIndex: "tarif",
      width: 180,
      render: (tarif) => (
        <span>
          {tarif.toLocaleString("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      title: "JUMLAH KEMASAN",
      key: "seri_pita_golongan",
      dataIndex: "seri_pita_golongan",
      width: 180,
    },

    {
      title: "CUKAI",
      key: "cukai",
      dataIndex: "cukai",
      // render: (jumlah_pesan) => (
      //   <span>
      //     {jumlah_pesan.toLocaleString("id-ID", {
      //       style: "currency",
      //       currency: "IDR",
      //       minimumFractionDigits: 2,
      //     })}
      //   </span>
      // ),
      fixed: "right",
    },
  ];

  return (
    <Fragment>
      <div>
        <Card title="DATA HEADER">
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NPPBKC"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nppbkc} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NAMA PERUSAHAAN"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nama_perusahaan} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-12 col-md-18 col-sm-24">
              <Form.Item
                label={"ALAMAT PERUSAHAAN"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <TextArea value={dataHeader[0].nama_kantor} disabled />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NO CK-1"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nomor_pppc} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"TANGGAL CK-1"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={moment(dataHeader[0].tanggal_pppc).format("DD-MM-YYYY")} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"JENIS BAYAR"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nomor_pppc} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"JENIS MMEA"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nomor_pppc} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"JENIS BAYAR"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nomor_pppc} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"JENIS PRODUKSI"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nomor_pppc} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"LOKASI AMBIL PITA"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nomor_pppc} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"GOLONGAN PABRIK"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nomor_pppc} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"IDENTITAS KUASA AMBIL PITA"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nomor_pppc} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NAMA KUASA AMBIL PITA"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nomor_pppc} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"BUKTI BAYAR"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].periode_pppc} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"TANGGAL LUNAS"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={moment(dataHeader[0].tanggal_periode).format("DD-MM-YYYY")} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NAMA PENGAMBIL PITA"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nomor_rekomendasi_ikk} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"TANGGAL AMBIL PITA"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].tanggal_rekomendasi_ikk} />
              </Form.Item>
            </div>
          </div>
        </Card>
        <Card title="RINCIAN">
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <Table
              dataSource={dataHeader[0].detil}
              columns={tbl_mmea}
              scroll={{
                x: 2200,
              }}
            />
          </div>
        </Card>
      </div>
    </Fragment>
  );
}
export default DetilMMEANonPita;
