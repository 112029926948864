import React, { useState, useEffect, Fragment } from "react";
import { Card, Form, Input, Table } from "antd";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";

function DetilNppbkc(props) {
  const [dataHeader, setDataHeader] = useState([""]);
  const nppbkc = props.nppbkc;
  console.log("NPPBKC: ", nppbkc);

  useEffect(() => {
    const getDataHeader = () => {
      axios
        .get("https://one-delta-api-1020394607917.asia-southeast2.run.app/nppbkc/detil?nppbkc=" + props.nppbkc, {
          headers: {},
        })
        .then((res) => {
          const result = res.data.body.data.data;
          setDataHeader(result);
          console.log("HEADER: ", result);
        })
        .catch((error) => {});
    };
    getDataHeader();
  }, [nppbkc]);

  console.log("HEADER BAWAH: ", dataHeader[0].npwp);

  const tbl_alamat = [
    {
      title: "JENIS ALAMAT",
      width: 180,
      key: "jenis_alamat",
      dataIndex: "jenis_alamat",
      fixed: "left",
    },
    {
      title: "ALAMAT",
      key: "alamat",
      dataIndex: "alamat",
      width: 800,
    },
    {
      title: "KODE POS",
      key: "kode_pos",
      dataIndex: "kode_pos",
    },
    {
      title: "TELEPON",
      key: "telepon",
      dataIndex: "telepon",
    },
    {
      title: "FAX",
      key: "fax",
      dataIndex: "fax",
    },
    {
      title: "PROVINSI",
      key: "provinsi",
      dataIndex: "provinsi",
    },
    {
      title: "KAB/KOTA",
      key: "kabupaten_kota",
      dataIndex: "kabupaten_kota",
    },
    {
      title: "KELURAHAN",
      key: "kelurahan",
      dataIndex: "kelurahan",
    },
    {
      title: "KECAMATAN",
      key: "kecamatan",
      dataIndex: "kecamatan",
    },
    {
      title: "STATUS",
      key: "status_kepemilikkan",
      dataIndex: "status_kepemilikkan",
    },
    {
      title: "LUAS (M2)",
      key: "luas",
      dataIndex: "luas",
      width: 100,
      fixed: "right",
    },
  ];

  return (
    <Fragment>
      <div>
        <Card title="DATA HEADER">
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NPPBKC"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nppbkc} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NAMA PERUSAHAAN"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nama_perusahaan} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"PEMILIK"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].pemilik} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"ALAMAT PEMILIK"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                {/* <Input disabled value={dataHeader[0].alamat_pemilik} /> */} <TextArea value={dataHeader[0].alamat_pemilik} disabled />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NO SKEP NPPBKC"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].skep_nppbkc} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"TANGGAL SKEP NPPBKC"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={moment(dataHeader[0].tanggal_nppbkc).format("DD-MM-YYYY")} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NPWP"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].npwp} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"PPN"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].ppn} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"AMBIL PITA"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].npwp} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NPPBKKC 28"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nppbkc28} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NO SKEP CABUT/BEKU"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].skep_cabut_nppbkc} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"TANGGAL SKEP CABUT/BEKU"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].tanggal_cabut_nppbkc} />
              </Form.Item>
            </div>
          </div>
        </Card>
        <Card title="DATA ALAMAT">
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <Table
              dataSource={dataHeader[0].detil}
              columns={tbl_alamat}
              scroll={{
                x: 3200,
              }}
            />
          </div>
        </Card>
      </div>
    </Fragment>
  );
}
export default DetilNppbkc;
