import React, { useState, Fragment, useRef } from "react";
import { Card, Form, Spin } from "antd";
import html2canvas from "html2canvas";
import { QRCodeCanvas } from "qrcode.react";
import logoBeaCukai from "./bea-cukai.png";
import Swal from "sweetalert2";

function LabelMesin(props) {
  const [isPrinting, setIsPrinting] = useState(false);
  const qrCodeRefs = useRef([]);
  const NoIdentifikasi = props.NoIdentifikasi || []; // Default array kosong jika tidak ada nilai
  console.log("NoIdentifikasi LabelMesin: ", NoIdentifikasi);

  const printQRCode = async () => {
    setIsPrinting(true);

    try {
      // Filter elemen valid
      const validElements = qrCodeRefs.current.filter((ref) => ref !== null);

      if (validElements.length === 0) {
        Swal.fire({
          title: "Peringatan",
          text: "Tidak ada label yang bisa di cetak!",
          icon: "warning",
          confirmButtonText: "OK",
        });
        setIsPrinting(false);
        return;
      }

      /// Capture gambar dari elemen valid dengan high resolution
      const qrCodeImages = await Promise.all(
        validElements.map((ref) =>
          html2canvas(ref, {
            scale: 5, // Tingkatkan skala untuk resolusi tinggi
            useCORS: true, // Menghindari masalah CORS jika menggunakan gambar eksternal
          }).then((canvas) => canvas.toDataURL("image/png"))
        )
      );
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
          <head>
            <style>
              @page {
                size: A4 portrait;
                margin: 1cm;
              }
              body {
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
              }
              .page {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                gap: 30px;
                padding-left: 5mm; /* Jarak barcode dari tepi kiri 5mm */
                width: 100%;
                height: 100%;
                margin-bottom: 20px;
                page-break-after: always;
              }
              .qrcode-container {
                width: 9.5cm; /* 2 gambar per baris */
                height: 6.3cm;
                border: 1px #112d63;
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              }
              img {
                max-width: 100%;
                max-height: 100%;
              }
            </style>
          </head>
          <body>
      `);

      // Membagi QR Code ke dalam halaman
      const itemsPerPage = 8; // 2 gambar per baris, 4 baris per halaman
      const totalPages = Math.ceil(qrCodeImages.length / itemsPerPage);

      for (let page = 0; page < totalPages; page++) {
        printWindow.document.write(`<div class="page">`);
        qrCodeImages.slice(page * itemsPerPage, (page + 1) * itemsPerPage).forEach((image) => {
          printWindow.document.write(`
              <div class="qrcode-container">
                <img src="${image}" />
              </div>
            `);
        });
        printWindow.document.write(`</div>`);
      }

      printWindow.document.write(`
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
    } catch (error) {
      console.error("Terjadi kesalahan saat mencetak:", error);
      Swal.fire({
        title: "Peringatan",
        text: "Terjadi kesalahan saat mencetak!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } finally {
      setIsPrinting(false);
    }
  };

  return (
    <Fragment>
      <div>
        <Card title={<span>MENAMPILKAN {NoIdentifikasi.length} LABEL MESIN</span>}>
          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <button
                style={{
                  marginBottom: "30px",
                  padding: "5px 15px",
                  backgroundColor: isPrinting ? "#ccc" : "#007bff",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: isPrinting ? "not-allowed" : "pointer",
                }}
                onClick={printQRCode}
                disabled={isPrinting}>
                {isPrinting ? "Sedang Menyiapkan Cetakan..." : "Cetak QR Code"}
              </button>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12">
              <Form.Item colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%" }}>
                {/* Loading saat mencetak */}
                {isPrinting && (
                  <div
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 9999,
                    }}>
                    <Spin size="large" tip="Mohon Tunggu Sebentar" style={{ color: "white" }} />
                  </div>
                )}
                {/* Kontainer Grid */}
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "30px", // Jarak antar elemen
                  }}>
                  {NoIdentifikasi.map((id, index) => (
                    <div
                      key={index}
                      ref={(el) => (qrCodeRefs.current[index] = el)}
                      style={{
                        width: "9cm", // Sesuai ukuran cetak
                        height: "6cm",
                        border: "1px solid black",
                        borderRadius: "8px",
                        backgroundColor: "#112d63",
                        color: "white",
                        padding: "5mm",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}>
                      {/* QR Code + Text */}
                      <div style={{ textAlign: "center" }}>
                        <QRCodeCanvas
                          value={id || "DEFAULT"}
                          size={150}
                          style={{
                            backgroundColor: "white",
                            padding: "10px",
                            borderRadius: "5px",
                          }}
                        />
                        {/* Text Identifikasi */}
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            textAlign: "center",
                            display: "block",
                            marginTop: "10px",
                          }}>
                          {id || ""}
                        </span>
                      </div>

                      {/* Logo Bea Cukai */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "150px",
                          height: "150px",
                          borderRadius: "5px",
                        }}>
                        <img
                          src={logoBeaCukai}
                          alt="Bea Cukai Logo"
                          style={{
                            marginBottom: "30px",
                            width: "100px",
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </Form.Item>
            </div>
          </div>
        </Card>
      </div>
    </Fragment>
  );
}

export default LabelMesin;
