import React, { useState, useEffect, Fragment } from "react";
import { Card, Form, Input, Table, Select, Button, DatePicker, message } from "antd";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import { data } from "jquery";
import swal from "sweetalert";
import FormItem from "antd/es/form/FormItem";

const { Option } = Select;

function FormB(props) {
  const [dataHeader, setDataHeader] = useState([""]);
  const nppbkc = props.nppbkc;

  useEffect(() => {
    const getDataHeader = () => {
      axios
        .get("https://one-delta-api-1020394607917.asia-southeast2.run.app/registrasi/detil/reg-B?nppbkc=" + nppbkc, {
          headers: {},
        })
        .then((res) => {
          const result = res.data.body.data.data;
          setDataHeader(result);
          // console.log("HEADER: ", result);
        })
        .catch((error) => {});
    };

    getDataHeader();
  }, [nppbkc]);

  const onFinish = (values) => {
    const formData = new FormData();
    formData.append(
      "detils",
      JSON.stringify({
        jenisBangunan: values.jenisBangunan,
        alamat: values.alamat,
        fax: "",
        idRegB: "",
        idRegBAlamat: "",
        jenisBangunan: values.jenisBangunan,
        kabupatenKota: values.kabupatenKota,
        kecamatan: values.kecamatan,
        kelurahan: values.kelurahan,
        kodePos: values.kodePos,
        luas: "",
        provinsi: values.provinsi,
        statusPenguasaan: values.statusPenguasaan,
        telepon: 0,
      })
    );

    formData.append("kodeAktePendirian", values.kodeAktePendirian);
    formData.append("idRegB", "");
    formData.append("idRegistrasi", "");
    formData.append("kotaPerubahan", values.kotaPerubahan);
    formData.append("nomorAktePerubahan", values.nomorAktePerubahan);
    formData.append("nomorSkPendirian", values.nomorSkPendirian);
    formData.append("nomorSkPerubahan", values.nomorSkPerubahan);
    formData.append("notarisPendirian", values.notarisPendirian);
    formData.append("notarisPerubahan", values.notarisPerubahan);
    formData.append("tahunPendirian", values.tahunPendirian);
    formData.append("tanggalAktePerubahan", values.tanggalAktePerubahan);
    formData.append("tanggalSkPendirian", values.tanggalSkPendirian);
    formData.append("tanggalSkPerubahan", values.tanggalSkPerubahan);
    formData.append("aktePendirian", values.aktePendirian);
    formData.append("badanUsaha", values.badanUsaha);

    const options = {
      method: "POST",
      url: "https://one-delta-api-1020394607917.asia-southeast2.run.app/registrasi/simpan-reg-A",
      data: formData,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
      },
    };
    console.log(formData);
    axios(options)
      .then(function (response) {
        console.log(response);
        swal({
          title: "Selamat!",
          text: "Berhasil Rekam Mesin!",
          icon: "success",
        }).then((value) => {});
      })
      .catch(function (error) {
        message.error("Gagal simpan data");
        console.log(error);
      });
  };

  const tbl_formA = [
    {
      title: "JENIS USAHA",
      width: 100,
      key: "jenis_usaha",
      dataIndex: "jenis_usaha",
    },
    {
      title: "NOMOR",
      key: "skep_nppbkc",
      dataIndex: "skep_nppbkc",
      width: 100,
    },
    {
      title: "TANGGAL",
      key: "tanggal_nppbkc",
      dataIndex: "tanggal_nppbkc",
      width: 100,
    },
  ];

  return (
    <Fragment>
      <div>
        <Card title="Perusahaan">
          <Form>
            <div className="kt-portlet__body">
              <div className="kt-portlet__head-toolbar"></div>
              <div className="form-group form-group-xs row">
                <label className="col-2 col-form-label">NPWP</label>
                <div className="col-3">
                  <Input disabled value={dataHeader[0].nppbkc} />
                </div>
                <label className="col-1 col-form-label"></label>
                <label className="col-2 col-form-label">Nama Perusahaan</label>
                <div className="col-4">
                  <Input value={dataHeader[0].nama_perusahaan} disabled />
                </div>
              </div>
              <div className="form-group form-group-xs row">
                <label className="col-2 col-form-label">Alamat Perusahaan</label>
              </div>
              <div className="form-group form-group-xs row">
                <label className="col-2 col-form-label">Jenis Bangunan</label>
                <div className="col-3">
                  <FormItem name="jenisBangunan">
                    <Select
                      style={{
                        color: "black",
                        marginRight: "5px",
                        width: "100%",
                      }}>
                      <Option value="1">Gedung</Option>
                    </Select>
                  </FormItem>
                </div>
                <label className="col-1 col-form-label"></label>
                <label className="col-2 col-form-label">Kode Pos</label>
                <div className="col-3">
                  <FormItem name="kodePos">
                    <Input style={{ color: "black", marginRight: "5px" }} type="Text" />
                  </FormItem>
                </div>
              </div>
              <div className="form-group form-group-xs row">
                <label className="col-2 col-form-label">Alamat</label>
                <div className="col-12">
                  <FormItem name="alamat">
                    <TextArea />
                  </FormItem>
                </div>
              </div>
              <div className="form-group form-group-xs row">
                <label className="col-2 col-form-label">Telepon</label>
                <div className="col-3">
                  <FormItem name="telepon">
                    <Input style={{ color: "black", marginRight: "5px" }} type="number" />
                  </FormItem>
                </div>
                <label className="col-1 col-form-label"></label>
                <label className="col-2 col-form-label">Fax</label>
                <div className="col-3">
                  <FormItem name="fax">
                    <Input style={{ color: "black", marginRight: "5px" }} type="number" />
                  </FormItem>
                </div>
              </div>
              <div className="form-group form-group-xs row">
                <label className="col-2 col-form-label">Kelurahan</label>
                <div className="col-3">
                  <FormItem name="kelurahan">
                    <Input style={{ color: "black", marginRight: "5px" }} type="Text" />
                  </FormItem>
                </div>
                <label className="col-1 col-form-label"></label>
                <label className="col-2 col-form-label">Kecamatan</label>
                <div className="col-3">
                  <FormItem name="kecamatan">
                    <Input style={{ color: "black", marginRight: "5px" }} type="Text" />
                  </FormItem>
                </div>
              </div>
              <div className="form-group form-group-xs row">
                <label className="col-2 col-form-label">Kabupaten/Kota</label>
                <div className="col-3">
                  <FormItem name="kabupatenKota">
                    <Input style={{ color: "black", marginRight: "5px" }} type="text" />
                  </FormItem>
                </div>
                <label className="col-1 col-form-label"></label>
                <label className="col-2 col-form-label">Provinsi</label>
                <div className="col-3">
                  <FormItem name="provinsi">
                    <Input style={{ color: "black", marginRight: "5px" }} type="Text" />
                  </FormItem>
                </div>
              </div>
              <div className="form-group form-group-xs row">
                <label className="col-2 col-form-label">Status Penguasaan</label>
                <div className="col-3">
                  <FormItem name="statusPenguasaan">
                    <Input style={{ color: "black", marginRight: "5px" }} type="text" />
                  </FormItem>
                </div>
                <label className="col-1 col-form-label"></label>
                <label className="col-2 col-form-label">Luas Bangunan</label>
                <div className="col-3">
                  <FormItem name="luas">
                    <Input style={{ color: "black", marginRight: "5px" }} type="Text" />
                  </FormItem>
                </div>
              </div>

              <div className="form-group form-group-xs row">
                <div className="col-3">
                  <Button type="primary" htmlType="submit" block>
                    Submit
                  </Button>
                </div>
                <div className="col-3">
                  <Button type="primary">Reset</Button>
                </div>
              </div>
            </div>
          </Form>
        </Card>
        <Card>
          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <Table />
          </div>
        </Card>

        <Card title="Badan Usaha">
          <div className="kt-portlet__body">
            <div className="kt-portlet__head-toolbar"></div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Bentuk Badan Usaha</label>
              <div className="col-3">
                <FormItem name="badanUsaha">
                  <Select style={{ color: "black", marginRight: "5px" }} placeholder="Pilih Jenis Badan Usaha">
                    <Option value="1">Gedung</Option>
                  </Select>
                </FormItem>
              </div>
              <label className="col-1 col-form-label"></label>
              <label className="col-2 col-form-label">Nama Notaris Pendirian</label>
              <div className="col-3">
                <FormItem name="notarisPendirian">
                  <Input style={{ color: "black", marginRight: "5px" }} type="Text" />
                </FormItem>
              </div>
            </div>

            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Nomor Akte Pendirian</label>
              <div className="col-3">
                <FormItem name="aktePendirian">
                  <Input style={{ color: "black", marginRight: "5px" }} type="Text" />
                </FormItem>
              </div>
              <label className="col-1 col-form-label"></label>
              <label className="col-2 col-form-label">Tahun Pendirian</label>
              <div className="col-3">
                <FormItem name="tahunPendirian">
                  <Input style={{ color: "black", marginRight: "5px" }} type="Text" />
                </FormItem>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Nomor SK Menkeh Pendirian</label>
              <div className="col-3">
                <FormItem name="nomorSkPendirian">
                  <Input style={{ color: "black", marginRight: "5px" }} type="Text" />
                </FormItem>
              </div>
              <label className="col-1 col-form-label"></label>
              <label className="col-2 col-form-label">Tanggal SK Menkeh Pendirian</label>
              <div className="col-3">
                <FormItem name="tanggalSkPendirian">
                  <DatePicker placeholder="Tanggal" style={{ width: "100%" }} format="YYYY-MM-DD" />
                </FormItem>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Kode Akte Pendirian</label>
              <div className="col-3">
                <FormItem name="kodeAktePendirian">
                  <Input style={{ color: "black", marginRight: "5px" }} type="text" />
                </FormItem>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Nomor Akte Perubahan</label>
              <div className="col-3">
                <FormItem name="nomorAktePerubahan">
                  <Input style={{ color: "black", marginRight: "5px" }} type="number" />
                </FormItem>
              </div>
              <label className="col-1 col-form-label"></label>
              <label className="col-2 col-form-label">Tahun Akter Perubahan</label>
              <div className="col-3">
                <FormItem name="">
                  <Input style={{ color: "black", marginRight: "5px" }} type="text" />
                </FormItem>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Nomor SK Menkeh Perubahan</label>
              <div className="col-3">
                <FormItem name="nomorSkPerubahan">
                  <Input style={{ color: "black", marginRight: "5px" }} type="number" />
                </FormItem>
              </div>
              <label className="col-1 col-form-label"></label>
              <label className="col-2 col-form-label">Tanggal SK Menkeh Perubahan</label>
              <div className="col-3">
                <FormItem name="tanggalSkPerubahan">
                  <DatePicker placeholder="Tanggal" style={{ width: "100%" }} format="YYYY-MM-DD" />
                </FormItem>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Nama Notaris Perubahan</label>
              <div className="col-3">
                <Input style={{ color: "black", marginRight: "5px" }} type="text" />
              </div>
              <label className="col-1 col-form-label"></label>
              <label className="col-2 col-form-label">Kota Perubahan</label>
              <div className="col-3">
                <FormItem name="kotaPerubahan">
                  <Input style={{ color: "black", marginRight: "5px" }} type="Text" />
                </FormItem>
              </div>
            </div>

            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label"></label>
              <label className="col-2 col-form-label"></label>
              <label className="col-1 col-form-label"></label>
              <div className="col-3">
                <Button type="primary" htmlType="submit" block>
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </Fragment>
  );
}
export default FormB;
