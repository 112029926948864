import React from "react";
import { Switch, Route } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

// user
import Rasio from "../views/Rasio";

const RasioRouter = () => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={300}>
        <Switch>
          {/* <Route path="/dashboard" exact component={Index} /> */}
          {/* user */}
          <Route path="/rasio" exact component={Rasio} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default RasioRouter;
