import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { Row, Col, Image, Form, Button, Spinner } from "react-bootstrap";
import Card from "../../components/Card";

import auth1 from "../../assets/images/auth/01.png";

function Login() {
  //define state
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false); // State untuk loading spinner

  //define state validation
  const [validation, setValidation] = useState([]);

  //define history
  const history = useHistory();

  useEffect(() => {
    //check token
    if (localStorage.getItem("token")) {
      //redirect page dashboard
      history.push("/dashboard");
    }
  }, []);

  //function "loginHanlder"
  const loginHandler = async (e) => {
    e.preventDefault();

    // validasi
    if (!username) {
      setValidation({ username: ["*Username tidak boleh kosong"] });
      return;
    }

    if (!password) {
      setValidation({ password: ["*Password tidak boleh kosong"] });
      return;
    }

    //initialize request body object
    const requestBody = {
      username: username,
      password: password,
    };

    //initialize request headers
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    // Mulai loading
    setIsLoading(true);

    try {
      //send data to server
      const response = await axios.post("https://one-delta-api-1020394607917.asia-southeast2.run.app/user/login", requestBody, { headers });

      console.log("RESPON: ", response);
      //set token on localStorage
      localStorage.setItem("token", response.data.jwt);
      localStorage.setItem("username", requestBody.username);

      //redirect to dashboard
      history.push("/dashboard");
    } catch (error) {
      //assign error to state "validation"
      setValidation(error.response?.data || { message: "Login gagal. Silakan coba lagi." });
    } finally {
      // Selesai loading
      setIsLoading(false);
    }
  };

  return (
    <section className="login-content">
      <Row className="m-0 align-items-center bg-white vh-100">
        <Col md="6">
          <Row className="justify-content-center">
            <Col md="10">
              <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                <Card.Body>
                  <h3 className="logo-title ms-3 text-center">DELTA LINKS</h3>
                  <p className="logo-title ms-3 text-center">Login to stay connected.</p>
                  <hr />
                  <div className="card-body">
                    <hr />
                    {validation.message && <div className="alert alert-danger">{validation.message}</div>}
                    <form onSubmit={loginHandler}>
                      <div className="mb-3">
                        <label className="form-label">Username</label>
                        <input
                          className="form-control"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          placeholder="Masukkan Username"
                          disabled={isLoading}
                        />
                      </div>
                      {validation.username && <div className="alert alert-danger">{validation.username[0]}</div>}
                      <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Masukkan Password"
                          disabled={isLoading}
                        />
                      </div>
                      {validation.password && <div className="alert alert-danger">{validation.password[0]}</div>}

                      <div className="d-grid gap-2">
                        <button type="submit" className="btn btn-primary" disabled={isLoading}>
                          {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Login"}
                        </button>
                      </div>

                      {isLoading && (
                        <div className="text-center mt-3">
                          <Spinner animation="border" variant="primary" />
                          <p className="mt-2">Mohon tunggu sebentar...</p>
                        </div>
                      )}
                    </form>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <div className="sign-bg">
            <svg width="280" height="230" viewBox="0 0 431 398" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g opacity="0.05">
                <rect x="-157.085" y="193.773" width="543" height="77.5714" rx="38.7857" transform="rotate(-45 -157.085 193.773)" fill="#3B8AFF" />
                <rect x="7.46875" y="358.327" width="543" height="77.5714" rx="38.7857" transform="rotate(-45 7.46875 358.327)" fill="#3B8AFF" />
                <rect x="61.9355" y="138.545" width="310.286" height="77.5714" rx="38.7857" transform="rotate(45 61.9355 138.545)" fill="#3B8AFF" />
                <rect x="62.3154" y="-190.173" width="543" height="77.5714" rx="38.7857" transform="rotate(45 62.3154 -190.173)" fill="#3B8AFF" />
              </g>
            </svg>
          </div>
        </Col>
        <Col md="6" className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">
          <Image src={auth1} className="Image-fluid gradient-main animated-scaleX" alt="images" />
        </Col>
      </Row>
    </section>
  );
}

export default Login;
