import React, { Fragment, Component } from "react";
import { Row, Col, Card, Table, Form, Button, Drawer, Input, Space, Tag } from "antd";
import axios from "axios";
import SweetAlert from "sweetalert2";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import Detil from "./detil";
import Label from "./label";
import Rekam from "./rekam";
import Edit from "./update";
import dayjs from "dayjs";
import Swal from "sweetalert2";
const screenWidth = window.innerWidth;
const drawerWidth = screenWidth * 0.8;
const rekamWidth = screenWidth * 1;
const labelWidth = screenWidth * 0.7;

class Mesin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasil: [],
      pagination: {},
      loading: false,
      loadingSpin: true,
      showDetil: false,
      showLabel: false,
      showEdit: false,
      showRekam: false,
      token: localStorage.getItem("token"),
      username: localStorage.getItem("username"),
    };
  }

  fetch = async () => {
    this.setState({ loading: true });
    axios
      .get("https://one-delta-api-1020394607917.asia-southeast2.run.app/mesin/browse/mesin/pegawai", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: this.state.token,
        },
      })
      .then((data) => {
        const pagination = { ...this.state.pagination };
        pagination.total = data.data.body.data.length;
        console.log(">>>>", data.data.body.data.data);

        this.setState({
          hasil: data.data.body.data.data,
          pagination,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err.message);

        this.setState({
          loading: false,
          hasil: [],
          pagination: false,
        });
        SweetAlert.fire({
          title: "Oops...",
          text: "Data tidak ditemukan!",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  handleOnClose = () => {
    this.setState({
      showLabel: false,
      showDetil: false,
      showRekam: false,
      showEdit: false,
    });
  };

  rekamData = () => {
    this.setState({
      showRekam: true,
    });
  };

  detilData = (id_mesin) => {
    this.setState({
      id_mesin: id_mesin,
      showDetil: true,
    });
    console.log(id_mesin);
  };

  editData = (id_mesin) => {
    this.setState({
      id_mesin: id_mesin,
      showEdit: true,
    });
    console.log(id_mesin);
  };

  hapusMesin = (id_mesin) => {
    console.log(id_mesin);
    let data = {
      id_mesin: id_mesin,
      username: this.state.username,
    };

    const options = {
      method: "POST",
      url: "https://one-delta-api-1020394607917.asia-southeast2.run.app/mesin/hapus-mesin?idMesin=" + id_mesin + "&username=" + this.state.username,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: this.state.token,
      },
    };

    // Tambahkan SweetAlert ke proyek Anda
    SweetAlert.fire({
      title: "Konfirmasi",
      text: "Apakah Anda yakin?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    }).then((result) => {
      if (result.isConfirmed) {
        // Mengatur token jika diperlukan

        axios(options)
          .then(function (response) {
            console.log(response);
            SweetAlert.fire({
              title: "Selamat!",
              text: "Berhasil Hapus Mesin!",
              icon: "success",
            }).then((value) => {
              window.location.reload(); // Tambahkan tindakan setelah SweetAlert ditutup
            });
          })
          .catch(function (error) {
            SweetAlert.fire("GAGAL", "Gagal Simpan Data.", "info");
            console.log(error);
          });
      } else {
        SweetAlert.fire("Dibatalkan", "Perintah hapus dibatalkan.", "info");
      }
    });
  };

  handleTableChange = (pagination, filters) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      loading: true,
    });
    this.fetch({
      size: pagination.pageSize,
      page: pagination.current,
      ...filters,
    });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Cari ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Cari
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  componentDidMount() {
    this.fetch();
  }

  labelData = (NoIdentifikasi) => {
    // Jika NoIdentifikasi tidak kosong, lanjutkan
    this.setState({
      NoIdentifikasi: NoIdentifikasi,
      showLabel: true,
    });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
    console.log("DISINI: ", selectedRowKeys);
  };

  render() {
    const { selectedRowKeys } = this.state;
    const Tb_Mesin = [
      {
        title: "PERUSAHAAN",
        key: "nama_perusahaan",
        dataIndex: "nama_perusahaan",
        width: 100,
        fixed: "left",
        ...this.getColumnSearchProps("nama_perusahaan"),
        render: (text) => <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>{text || "-"}</div>,
      },
      {
        title: "MERK",
        key: "nama_merk",
        dataIndex: "nama_merk",
        width: 70,
        render: (text) => <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>{text || "-"}</div>,
      },
      {
        title: "JENIS",
        key: "jenis_mesin",
        dataIndex: "jenis_mesin",
        width: 70,
        render: (text) => <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>{text || "-"}</div>,
      },
      {
        title: "NO IDENTIFIKASI",
        key: "no_identifikasi",
        dataIndex: "no_identifikasi",
        width: 80,
        render: (text) => <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>{text || "-"}</div>,
      },
      {
        title: "WAKTU REKAM TERAKHIR",
        key: "updated_at",
        dataIndex: "updated_at",
        width: 100,
        render: (text) => (
          <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>{text ? dayjs(text).format("DD-MM-YYYY HH:MM") : "-"}</div>
        ),
      },
      {
        title: "STATUS",
        key: "flag_label",
        dataIndex: "flag_label",
        width: 60,
        fixed: "right",
        ...this.getColumnSearchProps("flag_label"),
        render: (text) => {
          if (text === "Y") {
            return <Tag color="green">Sudah Pelabelan</Tag>;
          } else if (text === "N") {
            return <Tag color="red">Belum Pelabelan</Tag>;
          } else {
            return <Tag color="default">-</Tag>; // Jika tidak ada nilai
          }
        },
      },
      {
        title: "#AKSI",
        key: "id_mesin",
        dataIndex: "id_mesin",
        width: 80,
        fixed: "right",
        render: (text, row) => (
          <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
            {row.id_mesin
              .toString()
              .split(",")
              .map((id_mesin) => {
                let button = (
                  <Button type="primary" size="medium" onClick={() => this.detilData(id_mesin)}>
                    <i className="fa fa-search" />
                  </Button>
                );
                let buttonHapus = (
                  <Button type="primary" danger="true" size="medium" onClick={() => this.hapusMesin(id_mesin)}>
                    <i className="fa fa-trash" />
                  </Button>
                );
                let buttonedit = (
                  <Button type="primary" size="medium" onClick={() => this.editData(id_mesin)}>
                    <i className="fa fa-edit" alt="edit data" />
                  </Button>
                );
                return (
                  <span>
                    {button} {buttonHapus} {buttonedit}
                  </span>
                );
              })}
          </div>
        ),
      },
    ];

    return (
      <Fragment>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card bordered={false} className="criclebox tablespace mb-24" title="BROWSE DATA MESIN">
                <Button size="medium" onClick={() => this.rekamData()} type="primary" style={{ position: "absolute", top: 20, right: 180 }}>
                  <i className="fa fa-edit" /> &nbsp; Tambah Mesin
                </Button>
                <Button size="medium" onClick={() => this.labelData()} type="primary" style={{ position: "absolute", top: 20, right: 22 }}>
                  <i className="fa fa-print" /> &nbsp; Generate Label
                </Button>
                <div className="table-responsive">
                  <Form>
                    <Table
                      rowSelection={{
                        selectedRowKeys,
                        onChange: this.onSelectChange,
                      }}
                      rowKey={(record) => record.no_identifikasi}
                      columns={Tb_Mesin}
                      dataSource={this.state.hasil}
                      pagination={this.state.pagination}
                      loading={this.state.loading}
                      onChange={this.handleTableChange}
                      scroll={{
                        x: 1500,
                      }}
                    />
                  </Form>
                </div>
              </Card>
            </Col>
          </Row>
          <Drawer title={"DETIL DATA MESIN"} placement="right" width={drawerWidth} open={this.state.showDetil} onClose={this.handleOnClose}>
            <Detil id_mesin={this.state.id_mesin} />
          </Drawer>
          <Drawer title={"REKAM DATA MESIN"} placement="right" width={rekamWidth} open={this.state.showRekam} onClose={this.handleOnClose}>
            <Rekam />
          </Drawer>
          <Drawer title={"EDIT DATA MESIN"} placement="right" width={rekamWidth} open={this.state.showEdit} onClose={this.handleOnClose}>
            <Edit id_mesin={this.state.id_mesin} />
          </Drawer>
          <Drawer title={"GENERATE LABEL MESIN"} placement="right" width={labelWidth} open={this.state.showLabel} onClose={this.handleOnClose}>
            <Label NoIdentifikasi={this.state.selectedRowKeys} />
          </Drawer>
        </div>
      </Fragment>
    );
  }
}

export default Mesin;
