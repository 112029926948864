import React, { useState, useRef, Fragment, useEffect } from "react";
import { Card, Form, Input, Button, Select, Upload, message } from "antd";
import axios from "axios";
import swal from "sweetalert";
import { UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

function Rekam(props) {
  const token = localStorage.getItem("token");
  const username = localStorage.getItem("username");
  const { Option } = Select;
  const [tipeMesin, setTipeMesin] = useState([""]);
  const [jenisProduksi, setjenisProduksi] = useState([""]);
  const [jenisMesin, setjenisMesin] = useState([""]);
  const [merkMesin, setMerkMesin] = useState([""]);
  const [dataNppbkc, setDataNppbkc] = useState([""]);
  const [nppbkc, setNppbkc] = useState("");
  const [namaPerusahaan, setnamaPerusahaan] = useState("");
  const [alamat, setAlamat] = useState("");
  const [location, setLocation] = useState({});
  const kode_kantor = localStorage.getItem("kode_kantor");
  const [noIdentifikasi, setnoIdentifikasi] = useState([""]);
  const [showKeteranganLainnya, setShowKeteranganLainnya] = useState(false);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          const latlng = lat + "," + lng;
          setLocation(latlng);

          // Mengambil data alamat menggunakan Geocoding API
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, [location]);

  const getDataNppbkc = () => {
    axios
      .get("https://one-delta-api-1020394607917.asia-southeast2.run.app/nppbkc/browse", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
        },
      })
      .then((res) => {
        const result = res.data.body.data.data;
        setDataNppbkc(result);
        console.log("nppbkc: ", result);
      })
      .catch((error) => {});
  };

  const getDataMerk = () => {
    axios
      .get("https://one-delta-api-1020394607917.asia-southeast2.run.app/mesin/referensi/merk-mesin", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
        },
      })
      .then((res) => {
        const result = res.data.body.data.data;
        setMerkMesin(result);
      })
      .catch((error) => {});
  };
  // Get Data Tipe Mesin
  const getDataTipeMesin = () => {
    axios
      .get("https://one-delta-api-1020394607917.asia-southeast2.run.app/mesin/referensi/tipe-mesin", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
        },
      })
      .then((res) => {
        const result = res.data.body.data.data;
        setTipeMesin(result);
      })
      .catch((error) => {});
  };

  // Get Data Jenis Produksi
  const getDataJenisProduksi = () => {
    axios
      .get("https://one-delta-api-1020394607917.asia-southeast2.run.app/mesin/referensi/jenisproduksi-mesin", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
        },
      })
      .then((res) => {
        const result = res.data.body.data.data;
        setjenisProduksi(result);
      })
      .catch((error) => {});
  };

  // Get Data getNomorIdentifikasi
  const getNomorIdentifikasi = (kode) => {
    if (!kode) {
      console.error("Kode tidak boleh kosong");
      return;
    }

    axios
      .get("https://one-delta-api-1020394607917.asia-southeast2.run.app/mesin/nomor-identifikasi?" + kode, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"), // Jika memerlukan token
        },
      })
      .then((res) => {
        const result = res.data;
        setnoIdentifikasi(result);
        console.log("result: ", result); // Pastikan `setjenisMesin` adalah fungsi state yang valid
      })
      .catch((error) => {
        console.error("Error fetching nomor identifikasi:", error);
      });
  };

  const handleGetNppbkc = (value, e) => {
    const selectedNppbkc = value;
    const selectedData = dataNppbkc.find((nbkc) => nbkc.nppbkc === selectedNppbkc);
    if (selectedData) {
      setNppbkc(selectedNppbkc);
      setnamaPerusahaan(selectedData.namaPerusahaan);
      setAlamat(selectedData.alamatPemilik);
    }
  };

  const handleJenisMesinChange = (value) => {
    // Simpan nilai ke konstanta
    const jenisMesinTerpilih = value;

    // Log nilai untuk memastikan

    if (jenisMesinTerpilih === "Pelinting") {
      setjenisMesin(1);
    } else if (jenisMesinTerpilih === "Pengemas") {
      setjenisMesin(2);
    } else {
      setjenisMesin("");
    }
    console.log("Jenis Mesin Terpilih:", jenisMesin);
  };

  const handleMerkMesinChange = (value) => {
    console.log("value: ", value);
    if (value === 1) {
      setShowKeteranganLainnya(true); // Tampilkan text field jika "Lainnya" dipilih
      console.log("MASUK");
    } else {
      setShowKeteranganLainnya(false);
      console.log("KELUAR"); // Sembunyikan text field
    }
  };

  const [isDrawerVisible, setIsDrawerVisible] = useState(false); // Kontrol visibilitas drawer
  const [form] = Form.useForm(); // Gunakan instance form

  const onFinish = (values) => {
    const formData = new FormData();
    formData.append(
      "mesinDetilPojo",
      JSON.stringify({
        noIdentifikasi: noIdentifikasi,
        namaPerusahaan: namaPerusahaan,
        nppbkc: nppbkc,
        jenisMesin: values.jenisMesin,
        idMerk: values.merkMesin,
        namaMerk: values.merkMesin,
        keteranganMerk: values.keteranganMerk,
        idTipe: values.idTipe,
        serialNumber: values.serial_number,
        idJenisProduksi: values.idJenisProduksi,
        tahunPembuatan: values.tahunPembuatan,
        negaraPembuat: values.negara,
        kapasitas: values.kapasitasMesin,
        kondisi: values.kondisiMesin,
        kepemilikan: values.statuskepemilikan,
        keterangan: values.keterangan,
        userRekam: username,
        userUpdate: username,
        alamat: alamat,
        longlat: location,
        namaFoto: "-",
        username: username,
        flagAktif: "Y",
      })
    );

    const options = {
      method: "POST",
      url: "https://one-delta-api-1020394607917.asia-southeast2.run.app/mesin/simpan-mesin",
      data: formData,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
      },
    };

    axios.defaults.headers.common["Authorization"] = `${token}`;
    axios(options)
      .then((response) => {
        console.log(response);

        swal({
          title: "Selamat!",
          text: "Berhasil Rekam Mesin!",
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        message.error("Gagal simpan data");
        console.log(error);
      });
  };

  // Untuk membuka drawer
  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  // Untuk menutup drawer tanpa aksi lainnya
  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  const onFinishFailed = (errorInfo) => {};

  useEffect(() => {
    getDataNppbkc();
    getDataJenisProduksi();
    getDataTipeMesin();
    getDataMerk();

    const kode = "idJenisMesin=" + jenisMesin + "&kodeKantor=" + kode_kantor + "&nppbkc=" + nppbkc;
    getNomorIdentifikasi(kode);
  }, [jenisMesin, nppbkc]);

  return (
    <Fragment>
      <div>
        <Card>
          <Form
            name="basic"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 24 }}
            // style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <Card title="Data Perusahaan">
              <Form.Item label="NPPBKC" name="nppbkc">
                <Select onChange={handleGetNppbkc}>
                  {dataNppbkc.map((nbkc) => (
                    <Option key={nbkc.nppbkc} value={nbkc.nppbkc}>
                      {nbkc.nppbkc} - {nbkc.namaPerusahaan}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Nama Perusahaan" name="namaPerusahaan">
                <input type="hidden" value={namaPerusahaan} onChange={(e) => setnamaPerusahaan(e.target.value)} />
                <Input disabled type="text" value={namaPerusahaan} onChange={(e) => setnamaPerusahaan(e.target.value)} />
              </Form.Item>

              <Form.Item label="Alamat" name="alamat">
                <input type="hidden" value={alamat} onChange={(e) => setAlamat(e.target.value)} />
                <Input disabled value={alamat} onChange={(e) => setAlamat(e.target.value)} />
              </Form.Item>
            </Card>
            <Card title="Data Mesin">
              <Form.Item
                label="Merk Mesin"
                name="merkMesin"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select onChange={(value) => handleMerkMesinChange(value)}>
                  {merkMesin
                    .filter((merk) => merk.idMerk) // Hanya data yang memiliki idMerk
                    .map((merk) => (
                      <Option key={merk.idMerk} value={merk.idMerk}>
                        {merk.nama}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              {showKeteranganLainnya && (
                <Form.Item
                  label="Nama Merk Baru"
                  name="keteranganMerk"
                  // rules={[
                  //   {
                  //     required: false,
                  //     message: "Harap isi keterangan lainnya!",
                  //   },
                  // ]
                  // }
                >
                  <Input placeholder="Nama Merk Baru" />
                </Form.Item>
              )}

              <Form.Item
                label="Jenis Mesin"
                name="jenisMesin"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select onChange={(value) => handleJenisMesinChange(value)}>
                  <Option value={"Pelinting"}>Pelinting</Option>
                  <Option value={"Pengemas"}>Pengemas</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Serial Number"
                name="serialNumber"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Input />
              </Form.Item>

              <Form.Item
                label="Tipe Mesin"
                name="idTipe"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select>
                  {tipeMesin.map((tipe) => {
                    return (
                      <Option key={tipe.idTipeMesin} value={tipe.idTipeMesin}>
                        {tipe.nama}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Jenis Produksi"
                name="idJenisProduksi"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select>
                  {jenisProduksi.map((jenis) => {
                    return (
                      <Option key={jenis.idJenisProduksi} value={jenis.idJenisProduksi}>
                        {jenis.nama}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item label="Tahun Pembuatan" name="tahunPembuatan">
                <Input type="number" />
              </Form.Item>

              <Form.Item
                label="Negara"
                name="negara"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Input />
              </Form.Item>

              <Form.Item label="Kapasitas Mesin" name="kapasitasMesin">
                <Input type="number" />
              </Form.Item>

              <Form.Item
                label="Kondisi Mesin"
                name="kondisiMesin"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select>
                  <Option value={"Baru"}>Baru</Option>
                  <Option value={"Bekas"}>Bekas</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Status kepemilikan"
                name="statuskepemilikan"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select>
                  <Option value={"Milik Sendiri"}>Milik Sendiri</Option>
                  <Option value={"Sewa"}>Sewa</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Keterangan"
                name="keterangan"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <TextArea rows={2} />
              </Form.Item>
            </Card>
            <Card>
              <Form.Item wrapperCol={{ offset: 0, span: 3, margintop: 80 }}>
                <Button size="large" type="primary" htmlType="submit" block>
                  Submit
                </Button>
              </Form.Item>
            </Card>
          </Form>
        </Card>
      </div>
    </Fragment>
  );
}
export default Rekam;
