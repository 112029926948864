import React, { useState, useEffect, Fragment } from "react";
import { Card, Form, Input, Table, Select, Button, DatePicker, message } from "antd";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import FormItem from "antd/es/form/FormItem";
import swal from "sweetalert";

const { Option } = Select;

function FormC(props) {
  const [dataHeader, setDataHeader] = useState([""]);
  const nppbkc = props.nppbkc;

  useEffect(() => {
    const getDataHeader = () => {
      axios
        .get("https://one-delta-api-1020394607917.asia-southeast2.run.app/registrasi/detil/reg-C?nppbkc=" + nppbkc, {
          headers: {},
        })
        .then((res) => {
          const result = res.data.body.data.data;
          setDataHeader(result);
          // console.log("HEADER: ", result);
        })
        .catch((error) => {});
    };

    getDataHeader();
  }, [nppbkc]);

  const onFinish = (values) => {
    const formData = new FormData();
    formData.append(
      "detils",
      JSON.stringify({
        alamat: values.alamat,
        fax: values.fax,
        idRegC: "",
        idRegCJabatan: "",
        jabatan: values.jabatan,
        jenisJabatan: values.jenisJabatan,
        kewarganegaraan: values.kewarganegaraan,
        kota: values.kota,
        nama: values.nama,
        npwp: values.npwp,
        telepon: "",
      })
    );

    formData.append("idRegC", "");
    formData.append("idRegistrasi", "");

    const options = {
      method: "POST",
      url: "https://one-delta-api-1020394607917.asia-southeast2.run.app/registrasi/simpan-reg-C",
      data: formData,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
      },
    };
    console.log(formData);
    axios(options)
      .then(function (response) {
        console.log(response);
        swal({
          title: "Selamat!",
          text: "Berhasil Rekam Mesin!",
          icon: "success",
        }).then((value) => {});
      })
      .catch(function (error) {
        message.error("Gagal simpan data");
        console.log(error);
      });
  };

  return (
    <Fragment>
      <div>
        <Card title="Data Penanggung Jawab dan Pengurus">
          <div className="kt-portlet__body">
            <div className="kt-portlet__head-toolbar"></div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Jenis Jabatan</label>
              <div className="col-3">
                <FormItem name="jenisJabatan">
                  <Select style={{ color: "black", marginRight: "5px" }} placeholder="Pilih Jenis Jabatan">
                    <Option value="1">Pelaksana</Option>
                  </Select>
                </FormItem>
              </div>
              <label className="col-1 col-form-label"></label>
              <label className="col-2 col-form-label">Jabatan</label>
              <div className="col-3">
                <FormItem name="jabatan">
                  <Input style={{ color: "black", marginRight: "5px" }} type="Text" />
                </FormItem>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Nama </label>
              <div className="col-3">
                <FormItem name="nama">
                  <Input style={{ color: "black", marginRight: "5px" }} type="Text" />
                </FormItem>
              </div>
              <label className="col-1 col-form-label"></label>
              <label className="col-2 col-form-label">NPWP</label>
              <div className="col-3">
                <FormItem name="npwp">
                  <Input style={{ color: "black", marginRight: "5px" }} type="number" />
                </FormItem>
              </div>
            </div>

            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Telepon</label>
              <div className="col-3">
                <FormItem name="telepon">
                  <Input style={{ color: "black", marginRight: "5px" }} type="number" />
                </FormItem>
              </div>
              <label className="col-1 col-form-label"></label>
              <label className="col-2 col-form-label">Fax</label>
              <div className="col-3">
                <FormItem name="fax">
                  <Input style={{ color: "black", marginRight: "5px" }} type="number" />
                </FormItem>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Alamat</label>
              <div className="col-9">
                <FormItem name="alamat">
                  <TextArea />
                </FormItem>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Kota</label>
              <div className="col-3">
                <FormItem name="kota">
                  <Input style={{ color: "black", marginRight: "5px" }} type="text" />
                </FormItem>
              </div>
              <label className="col-1 col-form-label"></label>
              <label className="col-2 col-form-label">Kewarganegaraan</label>
              <div className="col-3">
                <FormItem name="kewarganegaraan">
                  <Input style={{ color: "black", marginRight: "5px" }} type="Text" />
                </FormItem>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <div className="col-3">
                <Button type="primary" htmlType="submit" block>
                  Submit
                </Button>
              </div>
              <div className="col-3">
                <Button type="primary">Reset</Button>
              </div>
            </div>
          </div>
        </Card>
        <Card>
          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <Table />
          </div>
        </Card>

        <div className="form-group form-group-xs row">
          <label className="col-2 col-form-label"></label>
          <label className="col-2 col-form-label"></label>
          <label className="col-1 col-form-label"></label>
          <div className="col-3">
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default FormC;
